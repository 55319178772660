<template>
	<div class="hello">
		<div class="top_content">
			<div class="left_icon" @click="gotoback">
				<img src="../assets/back.png" alt="" />
			</div>
			<div class="center_content">发布规则</div>
		</div>
		<div class="roules_box">
			<p style="padding-bottom: 20px">
				欢迎优秀的您加入抖阴社区，我们珍视每一位UP主，始终致力于为大家带来最优质的产品与服务。您的作品在抖阴社区的售卖无时间、次数限制，销售的次数越多，获得的收入就会越多。无论您是在睡觉还是在工作，它们都将持续为您带来源源不断的收入。
			</p>

			<p>上传规则</p>
			<p>1.UP主及普通用户上传收费视频比例为2:1，即上传2个免费视频才可上传1个收费视频。</p>
			<p>2.原创举牌up主上传收费视频比例为1:1，即上传1个免费视频才可上传1个收费视频。</p>
			<p>3.视频清晰度需在360P以上，且时长不小于30秒。</p>
			<p>4.套图大于6张才可以设置价格。</p>
			<p>5.审核时间为48小时内，请在[创作中心]查收反馈。</p>
			<p style="padding-bottom: 20px">6.视频中的当事人须满18岁以上，且当事人同意视频被上传分享。</p>

			<p>审核规则</p>
			<p>1.原创拍摄、原创剪辑作品，会更容易通过并获得官方推荐。</p>
			<p>2.禁止直接搬运网络视频，重复率高且不容易通过，多次违规将降低账号推荐权重。</p>
			<p>3.禁止上传幼女、人兽、真实强奸等侵害他人的视频。</p>
			<p>4.加强用户隐私性，允许原创视频为人物面部等重要部分添加遮挡或马赛克。</p>
			<p style="padding-bottom: 20px">5.上传的视频内容不符合上传要求将不会通过审核，如若退回视频未作修改再次发起审核将禁止上传。</p>

			<p>定价规则</p>
			<p>1.发布内容默认为免费，用户可根据作品内容质量调整为金币视频。</p>
			<p>2.认证UP主发布原创举牌长视频，建议定价50-200金币</p>
			<p>3.原创举牌作品，建议定价30-50金币</p>
			<p>4.原创剪辑作品，建议定价10-20金币 5.非原创短片，建议定价1-10金币</p>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import SparkMD5 from 'spark-md5';
import rsa from '@/rsa/rsa.js';
import webview from '@/rsa/webview.js';

export default {
	name: 'HelloWorld',
	props: {
		msg: String
	},
	data() {
		return {
			selecttext: '选择帖子板块',
			uploadstatus: true,
			checkboxs: [],
			yhdata: '',
			currimgindex: 0,
			upimglist: [],
			uploadurl: 'http://videoupload.appinfo.ltd:2100',
			baseurl: 'https://api.admin91.top',
			filedata: '',
			imgdata: [],
			checked: false,
			UserId: '',
			Token: '',
			UserVid: '',
			progressbar: 0,
			upnum: false,
			shangchuangstatus: true,
			showupbar: false,
			upsuccess: false,
			uperror: false,
			zhuanmazhong: false,
			fabusuccess: false,
			fabuzhuangtai: true,
			texterrox: false,
			imgerror: false,
			pagebox: {
				forumTitle: '',
				forumContent: '',
				gold: 0,
				taggingId: '',
				videoPreviewURL: '',
				videoM3U8URL: '',
				duration: '',
				imageURL: '',
				forumType: 1
			},
			uploaddata: '',
			upimgresarray: []
		};
	},

	watch: {
		'pagebox.gold': {
			handler: function (newValue, oldValue) {
				console.log(newValue, oldValue);
				if (newValue > 100) {
					this.pagebox.gold = 0;
				}
			},
			deep: true
		}
	},

	mounted() {
		if (this.$route.params.id) {
			this.pagebox.taggingId = this.$route.params.id;
			this.selecttext = this.$route.params.title;

			this.UserId = this.$route.params.UserId;
			this.Token = this.$route.params.Token;
			this.UserVid = this.$route.params.UserVid;
		}
		if (this.$route.params.UserId) {
			this.UserId = this.$route.params.UserId;
			this.Token = this.$route.params.Token;
			this.UserVid = this.$route.params.UserVid;
		}
	},
	methods: {
		//返回uniapp
		gotoback() {
			// webview.postMessage({
			// 	data: '发布成功'
			// });
			this.$router.go(-1);
		}
	}
};
</script>
<style scoped>
* {
	padding: 0;
	margin: 0;
}
.hello {
	
	overflow-y: scroll;
	background-color: #090216;
	height: 100vh;
}
.inputbox .item {
	padding: 5px 0px 5px 0px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
}

.inputbox .item .zishu {
	position: absolute;
	right: 12px;
	bottom: 7px;
	font-size: 11px;
	color: #999;
}

.top_content {
	position: fixed;
	left: 0;
	right: 0;
	top: 0px;
	z-index: 99;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 0px 15px;
	flex-direction: row;
	background-color: #090216;
	padding-top: 40px;
}
.left_icon {
	width: 20px;
	height: 20px;
	display: flex;
}
.left_icon img {
	width: 20px;
	height: 20px;
}

.center_content {
	font-size: 20px;
	font-weight: bold;
	padding-left: 110px;
	color: #fff;
}

.roules_box {
	text-align: left;
	padding-top: 100px;
	padding-left: 15px;
	padding-right: 1px;
	color: #999;
}
.roules_box p {
	font-size: 13px;
	line-height: 19px;
}
</style>
